/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form {
	padding: 6rem;
	color: $c-white;
	background-color: $c-black; 

	@include breakpoint-down(small-desktop) {
		padding: 4rem;
	}

	@include breakpoint-down(tablet) {
		padding: 2rem;
	}

	.form__head {
		margin-bottom: 3.4rem;
	}

	.form__head h1 {
		margin-bottom: 2rem;
	}

	.form__head p {
		line-height: 1.65; 
	}

	.form__cols {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 0 -1rem -2rem;
		
		@include breakpoint-down(tablet) {
			margin: 0 -0.5rem -1rem;
		} 
	}

	.form__col {
		flex: 0 0 50%;
		max-width: 50%;
		padding: 0 1rem 2rem;

		@include breakpoint-down(tablet) {
			padding: 0 .5rem 1rem;
		}

		@include breakpoint-down(mobile-medium) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.form__col--large {
		max-width: 100%;
		flex: 0 0 100%;
	}
	
	.form__body {
		margin-bottom: 1.8rem;

		@include breakpoint-down(tablet) {
			margin-bottom: 1rem;
		}
	}

	.form__btn {
		border: 0;
		background-color: rgba($c-white, .9);
		color: $c-black;
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 1.26;
		padding: 2.9rem 5.5rem;
		transition: box-shadow $t-duration;

		&:hover {
			box-shadow: 0 -5rem 5rem inset rgba(175, 175, 175, .75);
		} 

		@include breakpoint-down(mobile) {
			padding: 1.5rem 1rem;	 	
		}	 
	}
}