/* ------------------------------------------------------------ *\
	Video
\* ------------------------------------------------------------ */

.video {
	padding-bottom: 33.3%;
	position: relative;
	background-color: rgba($c-black, .15); 
	z-index: 4;

		@include breakpoint-down(tablet) {
			width: 100%; 
			height: auto; 
		}

	@include breakpoint-down(mobile) {
		padding-bottom: 50%;
	}

	@include breakpoint-down(mobile-medium) {
		padding-bottom: 60%;
	}

	@include breakpoint-down(mobile-small) {
		padding-bottom: 70%;
	}

	video {
		position: absolute;
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
		object-fit: cover;
		object-position: center center;
	}

	&.samsung video {
		object-fit: fill;
		object-position: top center;
	}

	figure {
		position: absolute;
		top: 50%; 
		left: 50%; 
		transform: translate(-50%, -50%);
		margin-top: -2.1rem;
		width: 10rem;
		height: 10rem;
		line-height: 10rem;
		border-radius: 50%; 
		text-align: center;
		background-color: $c-darkgray;
		transition: opacity $t-duration;

		@include breakpoint-down(tablet) {
			width: 7.5rem;
			height: 7.5rem; 	
		} 
	}

	figure.is-active {
		opacity: 0;
		pointer-events: none;
	} 

	figure svg {
		display: inline-block;
		vertical-align: middle;
		fill: $c-white;
		margin: -0.3rem -0.6rem 0 0;

		@include breakpoint-down(tablet) {
			margin: -2.5rem -0.7rem 0 0;
		}
	}

	.video__title {
		position: absolute; 
		top: 50%;
		left: 0; 
		width: 100%; 
		text-align: center;
		transform: translateY(-50%);
		margin-top: 6rem;
		padding-left: 1rem;
		text-transform: uppercase;
		color: $c-white;
		letter-spacing: 0.6em;

		@include breakpoint-down(tablet) {
			margin-top: 4rem;
			letter-spacing: 0.3em;
		}
	}

	.video__title.is-active {
		top: 0;
		transform: none;
		margin: 0; 
		padding: 1rem 0; 
		background: linear-gradient(to bottom, rgba($c-black, .7), rgba($c-black, 0));
		pointer-events: none;
	} 
}

.video--small {
	padding-bottom: 47.5%;

	@include breakpoint-down(mobile) {
		padding-bottom: 60%;
	}

	@include breakpoint-down(mobile-small) {
		padding-bottom: 70%;
	}

	figure {
		margin-top: -0.8rem;
	}

	.video__title {
		position: absolute;
		top: auto;
		transform: none; 
		bottom: 0;
		width: auto;
		letter-spacing: 0;
		margin-top: 0;
		text-transform: capitalize;
		left: 0; 
		color: $c-white;
		background-color: $c-black;
		padding: 2.1rem 3.7rem 2.1rem 3rem;  
	}

	.video__title.is-active {
		bottom: auto;
		width: 100%; 
	}
}