/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
	background-color: $c-black; 
	color: $c-white;
	font-size: 1.8rem;

	ul {
		display: flex;
		height: 100%;
		align-items: center;

		@include breakpoint-down(mobile) {
			flex-direction: column;
		}
	}

	li {
		display: inline-block;
		flex: 0 0 auto;

		@include breakpoint-down(mobile) {
			padding: 2rem 1rem;
		}

		@include breakpoint-down(mobile) {
			padding: 2rem 1.5rem;
		}
	}

	li + li {
		margin-left: 2.1rem;

		@include breakpoint-down(mobile) {
			margin: 0;
		}
	}

	li:hover a:before {
		width: 100%;
	}

	a {
		text-decoration: none;
		position: relative;

		@include breakpoint-down(mobile) {
			text-align: center;
		}

		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 0;
			height: 1px;
			background-color: $c-white;
			transition: width $t-duration;
		}
	}
}
