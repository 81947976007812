/* ------------------------------------------------------------ *\
	Section Form
\* ------------------------------------------------------------ */

.section-form {
	padding: 27.9rem 0 17.5rem;

	@include breakpoint-down(tablet) {
		padding-bottom: 5rem;
	}

	@include breakpoint-down(mobile) {
		padding-top: 17rem;
	}

	.shell {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		position: relative;
		z-index: 4; 
	}

	.section__image {
		flex: 0 0 40.21%;
		max-width: 40.21%;

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;			
		}
	}

	.section__image figure {
		margin: -7.4rem -10.2rem 0 0;

		@include breakpoint-down(mobile) {
			margin: 0;
		}
	}

	.section__image img {
		height: auto; 
		width: 100%; 
	}

	.form {
		flex: 0 0 59.79%;
		max-width: 59.79%;

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;			
		}
	}
}