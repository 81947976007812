/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	.footer__shapes {
		padding-bottom: 15.1rem;
		position: relative;

		@include breakpoint-down(mobile) {
			padding-bottom: 10rem;
		}
	}

	.footer__shapes .shell {
		position: relative;
	}

	.footer__shape {
		position: absolute;
		bottom: -0.5rem; 
		left: 0;

		@include breakpoint-down(mobile) {
			bottom: 0;
		}
	}

	.footer__shape svg {
		fill: none;
		stroke: $c-black;
	}

	.footer__shape--alt {
		position: absolute;
		right: 8.6%; 
		top: 0; 

		@include breakpoint-down(mobile) {
			top: 2rem;
		}

		@include breakpoint-down(mobile-small) {
			top: 1rem;
		}
	}

	.footer__shape--alt svg {
		fill: $c-black;
		stroke: none;	
	}
}
