/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	appearance: none;
}

/*  Btn block  */

.btn--block {
	display: block;
	padding-left: 0;
	padding-right: 0;
}
