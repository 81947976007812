/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
	position: absolute;
	top: 0; 
	left: 0; 
	width: 100%;
	z-index: 3;

	@include breakpoint-down(small-desktop) {
		z-index: 10; 	
	} 

	.shell {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.nav {
		transform: translateX(10%); 
		padding: 0 2.5rem;
		transition: transform $t-duration, opacity $t-duration;
		opacity: 0;
		pointer-events: none;

		@include breakpoint-down(mobile) {
			max-width: 100%;
			transform: translateY(-10%);
			padding: 0 1rem;
		}

		@include breakpoint-down(mobile-small) {
			padding: 0;
		}
	}

	.header__content {
		margin-top: 2.7rem;
		display: inline-flex;
		justify-content: space-between;
		position: relative;

		@include breakpoint-down(mobile) {
			position: absolute;
			top: 0; 
			right: 1.5rem; 
			flex-direction: column-reverse;
			align-items: flex-end;
		}
	}

	.header__content.is-active .nav {
		transform: translateX(0); 
		opacity: 1;
		pointer-events: all;

		@include breakpoint-down(mobile) {
			transform: translateY(0);
		}
	}
}
