/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
	font-family: 'Plus Jakarta Sans';
	src: url('../fonts/PlusJakartaSans-Regular.woff2') format('woff2'),
		url('../fonts/PlusJakartaSans-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Plus Jakarta Sans';
	src: url('../fonts/PlusJakartaSans-Bold.woff2') format('woff2'),
		url('../fonts/PlusJakartaSans-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}