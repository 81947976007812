/* ------------------------------------------------------------ *\
	Hero
\* ------------------------------------------------------------ */

.hero {
	font-size: 1.6rem;
	line-height: 1.44;
	position: relative;
	overflow: hidden;

	.shell {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.hero__content {
		padding: 22.3rem 7rem 13rem 2.4rem;
		max-width: 33.4%;
		flex: 0 0 33.4%;

		@include breakpoint-down(large-desktop) {
			padding: 17rem 6rem 10rem 2.4rem;
		}

		@include breakpoint-down(small-desktop) {
			max-width: 35%;
			flex: 0 0 35%;
			padding: 17rem 2rem 5rem;
		}

		@include breakpoint-down(tablet) {
			padding: 17rem 1.5rem 7.5rem 0;
		}

		@include breakpoint-down(mobile) {
			padding: 14rem 1.5rem 3rem 0;
			max-width: 100%;
			flex: 0 0 100%;
		}
	}

	.hero__image {
		width: auto;
		max-width: 66.6%;
		flex: 0 0 66.6%;
		top: 0;
		height: auto;

		@include breakpoint-down(small-desktop) {
			max-width: 65%;
			flex: 0 0 65%;
		}

		@include breakpoint-down(tablet) {
			align-self: flex-end;
		}

		@include breakpoint-down(mobile) {
			max-width: 100%;
			flex: 0 0 100%;
		}
	}

	.hero__image figure {
		width: calc( 100vw - (((100vw - #{$shell-max-width}) / 2) + (#{$shell-max-width} * 0.334)));
		height: auto;

		@include breakpoint-down(small-desktop) {
			width: calc( 100vw - (((100vw - #{$shell-max-width}) / 2) + (#{$shell-max-width} * 0.35)));
		}

		@include breakpoint-down(mobile) {
			width: 100%; 
		}
	}

	.hero__image img {
		width: 100%; 
		height: auto;
	}


	.hero__content h5 {
		text-transform: uppercase;
		letter-spacing: 0.2em;
		margin: 0 0 2.8rem -2.4rem;

		@include breakpoint-down(large-desktop) {
			margin-bottom: 1.5rem;
		}

		@include breakpoint-down(small-desktop) {
			margin: 0 0 1rem -2rem;
		}

		@include breakpoint-down(tablet) {
			margin-left: 0;
		}
	}

	.hero__content h1 {
		margin-bottom: 2rem;

		@include breakpoint-down(large-desktop) {
			margin-bottom: 1.5rem;
		}

		@include breakpoint-down(small-desktop) {
			margin-bottom: 1rem;
		}
	}

	.hero__content p {
		margin: 0; 
	}

	.hero__content p + p {
		margin-top: 2.3rem; 

		@include breakpoint-down(large-desktop) {
			margin-top: 1.5rem;
		}
	}
}

.hero + .section {
	margin-top: -6.5rem;
	position: relative;

	@include breakpoint-down(small-desktop) {
		margin-top: -3rem;
	}

	@include breakpoint-down(mobile) {
		margin-top: 0;
	}
}