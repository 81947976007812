/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */

.wrapper {
	position: relative;
	min-height: 100vh;
	background-image: url('../images/temp/noise-3.png');
	background-color: $c-beige;
	background-repeat: repeat;
	background-position: 0 0;
	overflow: hidden;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
		background-image: url('../images/temp/Noise2.png');
		pointer-events: none;
	}
}
