/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

html {
	font-size: 62.5%;
}

body {
	min-width: 320px;
	background: #fff;
	font-family: $ff-plus-jakarta-sans;
	font-weight: $font-weight-base;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: #000;

	@include breakpoint-down(mobile) {
		font-size: $font-size-mobile;
	}
}

a {
	color: inherit;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}

	&[href^="tel"] {
		text-decoration: none;
	}
}


.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
	margin-bottom: calc(#{$line-height-base}em / 2);
}

.h1,
h1 {
	font: 700 3.6rem/1.26 $ff-plus-jakarta-sans;

	@include breakpoint-down(mobile) {
		font-size: 2.5rem;
	}
}

.h2,
h2 {
	font: 700 3.1rem/1.26 $ff-plus-jakarta-sans;

	@include breakpoint-down(mobile) {
		font-size: 2.2rem;
	}
}

.h3,
h3 {
	font: 700 2.6rem/1.26 $ff-plus-jakarta-sans;

	@include breakpoint-down(mobile) {
		font-size: 1.8rem;
	}
}

.h4,
h4 {
	font: 700 2rem/1.26 $ff-plus-jakarta-sans;

	@include breakpoint-down(mobile) {
		font-size: 1.4rem;
	}
}

.h5,
h5 {
	font: 700 1.6rem/1.26 $ff-plus-jakarta-sans;

	@include breakpoint-down(mobile) {
		font-size: 1.3rem;
	}
}

.h6,
h6 {
	font: 700 1.2rem/1.26 $ff-plus-jakarta-sans;
}


p,
ul,
ol,
dl,
hr,
table,
blockquote {
	margin-bottom: #{$line-height-base}em;
}

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
	margin-bottom: 0;
}
