/* ------------------------------------------------------------ *\
	List Partners
\* ------------------------------------------------------------ */

.list-partners {
	list-style-type: none;

	@include breakpoint-down(large-desktop) {
		column-count: 4;
		margin-bottom: -6rem;
	}

	@include breakpoint-down(mobile) {
		column-count: 2;
	}

	@include breakpoint-down(mobile-medium) {
		column-count: 1;
	}

	li {
		display: inline-block;

		@include breakpoint-down(large-desktop) {
			width: 100%;
			padding-bottom: 3rem;
		}

		@include breakpoint-down(tablet) {
			padding: 0 1rem 6rem;
		}	
	}

	li + li {
		margin-left: 3.6rem;
		font-size: 0;

		@include breakpoint-down(large-desktop) {
			margin-left: 0;
		}
	}

	a {
		display: inline-block;
		text-align: center;
		width: 100%;
		line-height: 3.5rem;
		font-size: $font-size-base;
	}

	li:hover img {
		opacity: .5;
	}

	img {
		display: inline-block;
		vertical-align: middle;
		line-height: 0;
		font-size: 0;
		transition: opacity $t-duration;
	}
}