/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
	display: inline-block;
	font-size: 0;

	img {
		@include breakpoint-down(mobile) {
			width: 9.5rem; 
		}
	}
}
