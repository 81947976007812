/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
	position: relative;
	z-index: 2;

	.shell {
		display: flex;
		flex-wrap: wrap-reverse;
	}

	.section__nav {	
		font-size: 1.5rem;
		background-color: $c-black;
		flex: 0 0 65.84%;
		max-width: 65.84%;
		color: $c-white;
		padding: 4rem 4rem 3.8rem; 

		@include breakpoint-down(small-desktop) {
			flex: 0 0 60%;
			max-width: 60%;
		}

		@include breakpoint-down(tablet) {
			flex: 0 0 65%;
			max-width: 65%;	
			padding: 2rem;
		}

		@include breakpoint-down(mobile) {
			padding: 1.5rem;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.section__nav h6 {
		text-transform: uppercase;
		letter-spacing: 0.2em;
		margin-bottom: 2.3rem;

		@include breakpoint-down(mobile) {
			margin-bottom: 1.5rem;
		}
	}

	.section__nav ul {
		list-style: none;
		display: flex;
		justify-content: space-between;
		margin: 0 -1.5rem;

		@include breakpoint-down(tablet) {
			margin: 0 -0.5rem;
		}

		@include breakpoint-down(mobile-medium) {
			flex-wrap: wrap;
			margin: 0 0 -1rem;
		}
	}

	.section__nav li {
		flex: 0 1 auto;
		width: 100%;
		padding: 0 1.5rem;

		@include breakpoint-down(tablet) {
			padding: 0 0.5rem;
		}

		@include breakpoint-down(mobile-medium) {
			flex: 0 0 100%;
			padding: 0 0 1rem;
		}
	}

	.section__nav li.is-active a{
		color: $c-black;
		background-color: rgba($c-white, .9);
	}

	.section__nav li:hover a {
		box-shadow: 0 -5rem 5rem inset rgba(125, 125, 125, .75);
	}

	.section__nav a {
		display: inline-block;
		text-decoration: none;
		padding: 2.9rem 1rem 2.8rem;
		border: 0.1rem solid rgba($c-white, .3);
		font-weight: 700; 
		width: 100%; 
		text-align: center;
		transition: box-shadow $t-duration;
		position: relative;
		overflow: hidden;

		@include breakpoint-down(tablet) {
			padding: 2rem 1rem;
		}

		@include breakpoint-down(mobile) {
			padding: 1.5rem 0.75rem;
		}
	}

	.footer__bar a {
		line-height: 1.44;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	.section__aside {
		padding-top: 5.1rem;
		max-width: 34.16%;
		flex: 0 0 34.16%;
		text-align: right;
		font-size: 1.6rem;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		@include breakpoint-down(small-desktop) {
			flex: 0 0 40%;
			max-width: 40%;
		}

		@include breakpoint-down(tablet) {
			flex: 0 0 35%;
			max-width: 35%;
			padding-top: 4.2rem;
			flex-direction: column;	
			justify-content: center;
		}

		@include breakpoint-down(mobile) {
			flex-direction: row;
			padding: 2rem 0;
			flex: 0 0 100%;
			max-width: 100%;
			font-size: 1.4rem;
		}
	}

	.section__aside h3 {
		display: inline-block;
		margin: 0 1.9rem 0 0;

		@include breakpoint-down(tablet) {
			display: block;
			margin: 0;
		}

		@include breakpoint-down(mobile) {
			margin-right: 2.5rem;
		}

		@include breakpoint-down(mobile-medium) {
			margin-right: 1.5rem;
		}
	}

	.section__aside a {
		line-height: 1.44;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

/* section centered */

.section--centered {
	padding: 0 0 9.9rem;
	position: relative;
	z-index: 4;

	@include breakpoint-down(mobile) {
		padding-bottom: 4rem;
	}

	.section__nav {
		margin: 0 auto; 

		@include breakpoint-down(tablet) {
			max-width: 100%;
			flex: 0 0 100%;
		}
	}

	.section__nav ul {
		margin: 0 -1.1rem;

		@include breakpoint-down(mobile) {
			margin: 0 -1rem;
		}

		@include breakpoint-down(mobile-medium) {
			margin: 0 0 -1rem;
		}
	}

	.section__nav li {
		padding: 0 1.1rem;


		@include breakpoint-down(mobile) {
			padding: 0 1rem;
		}

		@include breakpoint-down(mobile-medium) {
			padding: 0 0 1rem;
		}
	}
}