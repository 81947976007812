/* ------------------------------------------------------------ *\
	Section Partners
\* ------------------------------------------------------------ */

.section-partners {
	padding: 6.5rem 0 11.2rem;

	@include breakpoint-down(small-desktop) {
		padding-bottom: 7rem;
	}

	@include breakpoint-down(tablet) {
		padding: 3rem 0 5rem;
	}

	h6 {
		font-size: 1.4rem;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		line-height: 1.26; 
		padding-left: 2.4rem;
		margin: 0 0 4.5rem;

		@include breakpoint-down(tablet) {
			padding-left: 0;
		}
	}

	.list-partners {
		max-width: 98.1%;
		margin-left: auto;

		@include breakpoint-down(large-desktop) {
			max-width: 100%;
		}
	}
}