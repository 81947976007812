/* ------------------------------------------------------------ *\
	Section Video
\* ------------------------------------------------------------ */

.section-video {
	padding: 20.5rem 0 2.5rem;

	@include breakpoint-down(mobile) {
		padding-top: 17rem; 
	}

	.section__content {
		position: relative;
		padding-bottom: 33.2%;
	}
}