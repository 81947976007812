/* ------------------------------------------------------------ *\
	Section About
\* ------------------------------------------------------------ */

.section-about {
	padding: 2.3rem 0 5rem;
	text-align: center;

	.section__content {
		max-width: 50%;
		margin: 0 auto;

		@include breakpoint-down(tablet) {
			max-width: 100%;
		}
	}

	.section__content h1 {
		margin-bottom: 2rem;
	}

	.section__content p {
		line-height: 1.65; 
	}
}