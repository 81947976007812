/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.article {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.article__content {
		flex: 0 0 44.85%;
		max-width: 44.85%;
		padding-left: 2.4rem;
		line-height: 1.75; 

		@include breakpoint-down(tablet) {
			padding-left: 0;
			flex: 0 0 49%;
			max-width: 49%;
		}

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;
			margin-bottom: 3rem;	
		}
	}

	.article__content h1 {
		margin-bottom: 3.1rem;

		@include breakpoint-down(mobile) {
			margin-bottom: 2rem;
		}
	}

	.article__content p span {
		display: inline-block;
	}

	.video {
		flex: 0 0 48.75%;
		max-width: 48.75%;
		margin-top: -2rem;

		@include breakpoint-down(small-desktop) {
			align-self: flex-start;
			margin-top: 0;
		}

		@include breakpoint-down(tablet) {
			flex: 0 0 49%;
			max-width: 49%;
		}

		@include breakpoint-down(mobile) {
			flex: 0 0 100%;
			max-width: 100%;	
		}
	}

}