/* ------------------------------------------------------------ *\
	Section Article
\* ------------------------------------------------------------ */

.section-article {
	padding: 29rem 0 5rem;

	@include breakpoint-down(tablet) {
		padding-bottom: 2.5rem;
	}

	@include breakpoint-down(mobile) {
		padding-top: 17rem; 
	}
}