//Variables
$font-size-base             : 2rem;
$font-size-mobile           : 1.4rem;
$font-weight-base           : 400;
$line-height-base           : 1.2;

$ff-plus-jakarta-sans 		: "Plus Jakarta Sans", Arial, sans-serif;

$t-duration                 : 0.4s;

$shell-max-width            : 120rem;
$shell-gutter               : 1.5rem;

$c-black                    : #000;
$c-white                    : #fff;
$c-base                     : #055590;
$c-beige 					: #FFF7EF;
$c-beige-2  				: #f2eae3;
$c-beige-3  				: #f9f1e9;
$c-darkgray 				: #282828;


$responsive-ios             : 812px;

$breakpoints: (
	mobile-small: 374px,
	mobile-medium: 575px,
	mobile: 767px,
	admin-bar: 782px,
	tablet: 1023px,
	small-desktop: 1199px,
	large-desktop: 1399px
);
