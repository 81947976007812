/* ------------------------------------------------------------ *\
	Fields
\* ------------------------------------------------------------ */

.field {
	max-width: 100%;
	width: 100%; 
	background-color: transparent;
	border: 1px solid rgba($c-white, .3);
	padding: 2.5rem 2rem 2.8rem;
	font-size: 1.7rem;
	border-radius: 0;
	color: rgba($c-white, .4);

	@include breakpoint-down(mobile) {
		padding: 1.5rem 1rem;
		font-size: 1.4rem;	 	
	}	 
}

/*  Field textarea  */
.field--textarea {
	resize: none;
}
