/* ------------------------------------------------------------ *\
  Vendor
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Generic
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
  SCSS Functions
\* ------------------------------------------------------------ */
/* example usage*/
/* ------------------------------------------------------------ *\
	Reset
\* ------------------------------------------------------------ */
@import '_sprite.css';
* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main {
  display: block;
}

template {
  display: none;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: none;
}

img,
iframe,
video,
audio,
object {
  max-width: 100%;
}

img,
iframe {
  border: 0 none;
}

img {
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

img,
[style*=background-image] {
  image-rendering: -webkit-optimize-contrast;
}

b,
strong {
  font-weight: bold;
}

address {
  font-style: normal;
}

svg:not(:root) {
  overflow: hidden;
}

a,
button,
input[type=submit],
input[type=button],
input[type=reset],
input[type=file],
input[type=image],
label[for] {
  cursor: pointer;
}

a[href^=tel],
button[disabled],
input[disabled],
textarea[disabled],
select[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input[type=text],
input[type=password],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=month],
input[type=week],
input[type=email],
input[type=number],
input[type=search],
input[type=tel],
input[type=time],
input[type=url],
input[type=color],
textarea,
a[href^=tel] {
  appearance: none;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  appearance: none;
}

textarea {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

button,
select {
  text-transform: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

nav ul,
nav ol {
  list-style: none outside none;
}

input::placeholder {
  color: inherit;
  opacity: 1;
}

textarea::placeholder {
  color: inherit;
  opacity: 1;
}

input:-webkit-autofill {
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

input[type=text],
input[type=button],
input[type=tel],
input[type=email],
input[type=submit],
input[type=date],
input[type=search],
textarea {
  appearance: none;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../fonts/PlusJakartaSans-Regular.woff2") format("woff2"), url("../fonts/PlusJakartaSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../fonts/PlusJakartaSans-Bold.woff2") format("woff2"), url("../fonts/PlusJakartaSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */
html {
  font-size: 62.5%;
}

body {
  min-width: 320px;
  background: #fff;
  font-family: "Plus Jakarta Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 2rem;
  line-height: 1.2;
  color: #000;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  body {
    font-size: 1.4rem;
  }
}

a {
  color: inherit;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}
a[href^=tel] {
  text-decoration: none;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  margin-bottom: calc(1.2em / 2);
}

.h1,
h1 {
  font: 700 3.6rem/1.26 "Plus Jakarta Sans", Arial, sans-serif;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .h1,
h1 {
    font-size: 2.5rem;
  }
}

.h2,
h2 {
  font: 700 3.1rem/1.26 "Plus Jakarta Sans", Arial, sans-serif;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .h2,
h2 {
    font-size: 2.2rem;
  }
}

.h3,
h3 {
  font: 700 2.6rem/1.26 "Plus Jakarta Sans", Arial, sans-serif;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .h3,
h3 {
    font-size: 1.8rem;
  }
}

.h4,
h4 {
  font: 700 2rem/1.26 "Plus Jakarta Sans", Arial, sans-serif;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .h4,
h4 {
    font-size: 1.4rem;
  }
}

.h5,
h5 {
  font: 700 1.6rem/1.26 "Plus Jakarta Sans", Arial, sans-serif;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .h5,
h5 {
    font-size: 1.3rem;
  }
}

.h6,
h6 {
  font: 700 1.2rem/1.26 "Plus Jakarta Sans", Arial, sans-serif;
}

p,
ul,
ol,
dl,
hr,
table,
blockquote {
  margin-bottom: 1.2em;
}

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
  margin-bottom: 0;
}

/* ------------------------------------------------------------ *\
  Helpers
\* ------------------------------------------------------------ */
/*  Clear  */
.clear:after {
  content: "";
  line-height: 0;
  display: table;
  clear: both;
}

/* sr-only */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*  Notext  */
.notext {
  white-space: nowrap;
  text-indent: 100%;
  text-shadow: none;
  overflow: hidden;
}

.notext-old {
  font-size: 0;
  line-height: 0;
  text-indent: -4000px;
}

/*  Hidden  */
[hidden],
.hidden {
  display: none;
}

/*  Disabled  */
[disabled],
.disabled {
  cursor: default;
}

/*  Responsive Helpers  */
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hidden-xs {
    display: none !important;
  }

  .visible-xs-block {
    display: block !important;
  }

  .visible-xs-inline {
    display: inline !important;
  }

  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 767.02px) and (max-width: 1023px) {
  .hidden-sm {
    display: none !important;
  }

  .visible-sm-block {
    display: block !important;
  }

  .visible-sm-inline {
    display: inline !important;
  }

  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1023.02px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }

  .visible-md-block {
    display: block !important;
  }

  .visible-md-inline {
    display: inline !important;
  }

  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1199.02px) {
  .hidden-lg {
    display: none !important;
  }

  .visible-lg-block {
    display: block !important;
  }

  .visible-lg-inline {
    display: inline !important;
  }

  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
/* ------------------------------------------------------------ *\
	Components
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */
.article {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.article .article__content {
  flex: 0 0 44.85%;
  max-width: 44.85%;
  padding-left: 2.4rem;
  line-height: 1.75;
}
@media (max-width: 1023px) {
  .article .article__content {
    padding-left: 0;
    flex: 0 0 49%;
    max-width: 49%;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .article .article__content {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 3rem;
  }
}
.article .article__content h1 {
  margin-bottom: 3.1rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .article .article__content h1 {
    margin-bottom: 2rem;
  }
}
.article .article__content p span {
  display: inline-block;
}
.article .video {
  flex: 0 0 48.75%;
  max-width: 48.75%;
  margin-top: -2rem;
}
@media (max-width: 1199px) {
  .article .video {
    align-self: flex-start;
    margin-top: 0;
  }
}
@media (max-width: 1023px) {
  .article .video {
    flex: 0 0 49%;
    max-width: 49%;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .article .video {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */
.btn {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  appearance: none;
}

/*  Btn block  */
.btn--block {
  display: block;
  padding-left: 0;
  padding-right: 0;
}

/* ------------------------------------------------------------ *\
	Checkbox
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Fields
\* ------------------------------------------------------------ */
.field {
  max-width: 100%;
  width: 100%;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 2.5rem 2rem 2.8rem;
  font-size: 1.7rem;
  border-radius: 0;
  color: rgba(255, 255, 255, 0.4);
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .field {
    padding: 1.5rem 1rem;
    font-size: 1.4rem;
  }
}

/*  Field textarea  */
.field--textarea {
  resize: none;
}

/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */
.footer .footer__shapes {
  padding-bottom: 15.1rem;
  position: relative;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .footer .footer__shapes {
    padding-bottom: 10rem;
  }
}
.footer .footer__shapes .shell {
  position: relative;
}
.footer .footer__shape {
  position: absolute;
  bottom: -0.5rem;
  left: 0;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .footer .footer__shape {
    bottom: 0;
  }
}
.footer .footer__shape svg {
  fill: none;
  stroke: #000;
}
.footer .footer__shape--alt {
  position: absolute;
  right: 8.6%;
  top: 0;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .footer .footer__shape--alt {
    top: 2rem;
  }
}
@media (max-width: 374px) {
  .footer .footer__shape--alt {
    top: 1rem;
  }
}
.footer .footer__shape--alt svg {
  fill: #000;
  stroke: none;
}

/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */
.form {
  padding: 6rem;
  color: #fff;
  background-color: #000;
}
@media (max-width: 1199px) {
  .form {
    padding: 4rem;
  }
}
@media (max-width: 1023px) {
  .form {
    padding: 2rem;
  }
}
.form .form__head {
  margin-bottom: 3.4rem;
}
.form .form__head h1 {
  margin-bottom: 2rem;
}
.form .form__head p {
  line-height: 1.65;
}
.form .form__cols {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -1rem -2rem;
}
@media (max-width: 1023px) {
  .form .form__cols {
    margin: 0 -0.5rem -1rem;
  }
}
.form .form__col {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 1rem 2rem;
}
@media (max-width: 1023px) {
  .form .form__col {
    padding: 0 0.5rem 1rem;
  }
}
@media (max-width: 575px) {
  .form .form__col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.form .form__col--large {
  max-width: 100%;
  flex: 0 0 100%;
}
.form .form__body {
  margin-bottom: 1.8rem;
}
@media (max-width: 1023px) {
  .form .form__body {
    margin-bottom: 1rem;
  }
}
.form .form__btn {
  border: 0;
  background-color: rgba(255, 255, 255, 0.9);
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.26;
  padding: 2.9rem 5.5rem;
  transition: box-shadow 0.4s;
}
.form .form__btn:hover {
  box-shadow: 0 -5rem 5rem inset rgba(175, 175, 175, 0.75);
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .form__btn {
    padding: 1.5rem 1rem;
  }
}

/* ------------------------------------------------------------ *\
	Grid
\* ------------------------------------------------------------ */
.grid {
  display: flex;
  flex-flow: row wrap;
  /*  Grid col 1of2  */
  /*  Grid col 1of3  */
}
.grid .grid__col {
  max-width: 100%;
  flex: 1;
}
.grid .grid__col--1of2 {
  max-width: 50%;
  flex: 0 0 50%;
}
.grid .grid__col--1of3 {
  max-width: 33.3333333333%;
  flex: 0 0 33.3333333333%;
}

/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}
@media (max-width: 1199px) {
  .header {
    z-index: 10;
  }
}
.header .shell {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.header .nav {
  transform: translateX(10%);
  padding: 0 2.5rem;
  transition: transform 0.4s, opacity 0.4s;
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .header .nav {
    max-width: 100%;
    transform: translateY(-10%);
    padding: 0 1rem;
  }
}
@media (max-width: 374px) {
  .header .nav {
    padding: 0;
  }
}
.header .header__content {
  margin-top: 2.7rem;
  display: inline-flex;
  justify-content: space-between;
  position: relative;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .header .header__content {
    position: absolute;
    top: 0;
    right: 1.5rem;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}
.header .header__content.is-active .nav {
  transform: translateX(0);
  opacity: 1;
  pointer-events: all;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .header .header__content.is-active .nav {
    transform: translateY(0);
  }
}

/* ------------------------------------------------------------ *\
	Hero
\* ------------------------------------------------------------ */
.hero {
  font-size: 1.6rem;
  line-height: 1.44;
  position: relative;
  overflow: hidden;
}
.hero .shell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.hero .hero__content {
  padding: 22.3rem 7rem 13rem 2.4rem;
  max-width: 33.4%;
  flex: 0 0 33.4%;
}
@media (max-width: 1399px) {
  .hero .hero__content {
    padding: 17rem 6rem 10rem 2.4rem;
  }
}
@media (max-width: 1199px) {
  .hero .hero__content {
    max-width: 35%;
    flex: 0 0 35%;
    padding: 17rem 2rem 5rem;
  }
}
@media (max-width: 1023px) {
  .hero .hero__content {
    padding: 17rem 1.5rem 7.5rem 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .hero__content {
    padding: 14rem 1.5rem 3rem 0;
    max-width: 100%;
    flex: 0 0 100%;
  }
}
.hero .hero__image {
  width: auto;
  max-width: 66.6%;
  flex: 0 0 66.6%;
  top: 0;
  height: auto;
}
@media (max-width: 1199px) {
  .hero .hero__image {
    max-width: 65%;
    flex: 0 0 65%;
  }
}
@media (max-width: 1023px) {
  .hero .hero__image {
    align-self: flex-end;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .hero__image {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
.hero .hero__image figure {
  width: calc(100vw - ((100vw - 120rem) / 2 + (120rem * 0.334)));
  height: auto;
}
@media (max-width: 1199px) {
  .hero .hero__image figure {
    width: calc(100vw - ((100vw - 120rem) / 2 + (120rem * 0.35)));
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .hero__image figure {
    width: 100%;
  }
}
.hero .hero__image img {
  width: 100%;
  height: auto;
}
.hero .hero__content h5 {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin: 0 0 2.8rem -2.4rem;
}
@media (max-width: 1399px) {
  .hero .hero__content h5 {
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 1199px) {
  .hero .hero__content h5 {
    margin: 0 0 1rem -2rem;
  }
}
@media (max-width: 1023px) {
  .hero .hero__content h5 {
    margin-left: 0;
  }
}
.hero .hero__content h1 {
  margin-bottom: 2rem;
}
@media (max-width: 1399px) {
  .hero .hero__content h1 {
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 1199px) {
  .hero .hero__content h1 {
    margin-bottom: 1rem;
  }
}
.hero .hero__content p {
  margin: 0;
}
.hero .hero__content p + p {
  margin-top: 2.3rem;
}
@media (max-width: 1399px) {
  .hero .hero__content p + p {
    margin-top: 1.5rem;
  }
}

.hero + .section {
  margin-top: -6.5rem;
  position: relative;
}
@media (max-width: 1199px) {
  .hero + .section {
    margin-top: -3rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero + .section {
    margin-top: 0;
  }
}

/* ------------------------------------------------------------ *\
	Icons
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	List Partners
\* ------------------------------------------------------------ */
.list-partners {
  list-style-type: none;
}
@media (max-width: 1399px) {
  .list-partners {
    column-count: 4;
    margin-bottom: -6rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .list-partners {
    column-count: 2;
  }
}
@media (max-width: 575px) {
  .list-partners {
    column-count: 1;
  }
}
.list-partners li {
  display: inline-block;
}
@media (max-width: 1399px) {
  .list-partners li {
    width: 100%;
    padding-bottom: 3rem;
  }
}
@media (max-width: 1023px) {
  .list-partners li {
    padding: 0 1rem 6rem;
  }
}
.list-partners li + li {
  margin-left: 3.6rem;
  font-size: 0;
}
@media (max-width: 1399px) {
  .list-partners li + li {
    margin-left: 0;
  }
}
.list-partners a {
  display: inline-block;
  text-align: center;
  width: 100%;
  line-height: 3.5rem;
  font-size: 2rem;
}
.list-partners li:hover img {
  opacity: 0.5;
}
.list-partners img {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  font-size: 0;
  transition: opacity 0.4s;
}

/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */
.logo {
  display: inline-block;
  font-size: 0;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .logo img {
    width: 9.5rem;
  }
}

/* ------------------------------------------------------------ *\
	Main
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Nav Trigger
\* ------------------------------------------------------------ */
.nav-trigger {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  background-color: #000;
  position: relative;
}
.nav-trigger span {
  display: inline-block;
  height: 0.2rem;
  width: 2.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  transition: opacity 0.4s;
}
.nav-trigger:before, .nav-trigger:after {
  content: "";
  position: relative;
  height: 0.2rem;
  width: 2.4rem;
  position: absolute;
  left: 50%;
  background-color: #fff;
  transform: translateX(-50%);
  transition: transform 0.4s;
}
.nav-trigger:before {
  top: 50%;
  transform: translate(-50%, calc(-50% - 0.5rem));
}
.nav-trigger:after {
  bottom: 50%;
  transform: translate(-50%, calc(50% + 0.5rem));
}
.nav-trigger.is-active span {
  opacity: 0;
}
.nav-trigger.is-active:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.nav-trigger.is-active:after {
  transform: translate(-50%, 50%) rotate(-45deg);
}

/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */
.nav {
  background-color: #000;
  color: #fff;
  font-size: 1.8rem;
}
.nav ul {
  display: flex;
  height: 100%;
  align-items: center;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .nav ul {
    flex-direction: column;
  }
}
.nav li {
  display: inline-block;
  flex: 0 0 auto;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .nav li {
    padding: 2rem 1rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .nav li {
    padding: 2rem 1.5rem;
  }
}
.nav li + li {
  margin-left: 2.1rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .nav li + li {
    margin: 0;
  }
}
.nav li:hover a:before {
  width: 100%;
}
.nav a {
  text-decoration: none;
  position: relative;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .nav a {
    text-align: center;
  }
}
.nav a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 1px;
  background-color: #fff;
  transition: width 0.4s;
}

/* ------------------------------------------------------------ *\
	Radio
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Section About
\* ------------------------------------------------------------ */
.section-about {
  padding: 2.3rem 0 5rem;
  text-align: center;
}
.section-about .section__content {
  max-width: 50%;
  margin: 0 auto;
}
@media (max-width: 1023px) {
  .section-about .section__content {
    max-width: 100%;
  }
}
.section-about .section__content h1 {
  margin-bottom: 2rem;
}
.section-about .section__content p {
  line-height: 1.65;
}

/* ------------------------------------------------------------ *\
	Section Article
\* ------------------------------------------------------------ */
.section-article {
  padding: 29rem 0 5rem;
}
@media (max-width: 1023px) {
  .section-article {
    padding-bottom: 2.5rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-article {
    padding-top: 17rem;
  }
}

/* ------------------------------------------------------------ *\
	Section Form
\* ------------------------------------------------------------ */
.section-form {
  padding: 27.9rem 0 17.5rem;
}
@media (max-width: 1023px) {
  .section-form {
    padding-bottom: 5rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-form {
    padding-top: 17rem;
  }
}
.section-form .shell {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  z-index: 4;
}
.section-form .section__image {
  flex: 0 0 40.21%;
  max-width: 40.21%;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-form .section__image {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.section-form .section__image figure {
  margin: -7.4rem -10.2rem 0 0;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-form .section__image figure {
    margin: 0;
  }
}
.section-form .section__image img {
  height: auto;
  width: 100%;
}
.section-form .form {
  flex: 0 0 59.79%;
  max-width: 59.79%;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-form .form {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* ------------------------------------------------------------ *\
	Section Partners
\* ------------------------------------------------------------ */
.section-partners {
  padding: 6.5rem 0 11.2rem;
}
@media (max-width: 1199px) {
  .section-partners {
    padding-bottom: 7rem;
  }
}
@media (max-width: 1023px) {
  .section-partners {
    padding: 3rem 0 5rem;
  }
}
.section-partners h6 {
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 1.26;
  padding-left: 2.4rem;
  margin: 0 0 4.5rem;
}
@media (max-width: 1023px) {
  .section-partners h6 {
    padding-left: 0;
  }
}
.section-partners .list-partners {
  max-width: 98.1%;
  margin-left: auto;
}
@media (max-width: 1399px) {
  .section-partners .list-partners {
    max-width: 100%;
  }
}

/* ------------------------------------------------------------ *\
	Section Video
\* ------------------------------------------------------------ */
.section-video {
  padding: 20.5rem 0 2.5rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-video {
    padding-top: 17rem;
  }
}
.section-video .section__content {
  position: relative;
  padding-bottom: 33.2%;
}

/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */
.section {
  position: relative;
  z-index: 2;
}
.section .shell {
  display: flex;
  flex-wrap: wrap-reverse;
}
.section .section__nav {
  font-size: 1.5rem;
  background-color: #000;
  flex: 0 0 65.84%;
  max-width: 65.84%;
  color: #fff;
  padding: 4rem 4rem 3.8rem;
}
@media (max-width: 1199px) {
  .section .section__nav {
    flex: 0 0 60%;
    max-width: 60%;
  }
}
@media (max-width: 1023px) {
  .section .section__nav {
    flex: 0 0 65%;
    max-width: 65%;
    padding: 2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__nav {
    padding: 1.5rem;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.section .section__nav h6 {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-bottom: 2.3rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__nav h6 {
    margin-bottom: 1.5rem;
  }
}
.section .section__nav ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0 -1.5rem;
}
@media (max-width: 1023px) {
  .section .section__nav ul {
    margin: 0 -0.5rem;
  }
}
@media (max-width: 575px) {
  .section .section__nav ul {
    flex-wrap: wrap;
    margin: 0 0 -1rem;
  }
}
.section .section__nav li {
  flex: 0 1 auto;
  width: 100%;
  padding: 0 1.5rem;
}
@media (max-width: 1023px) {
  .section .section__nav li {
    padding: 0 0.5rem;
  }
}
@media (max-width: 575px) {
  .section .section__nav li {
    flex: 0 0 100%;
    padding: 0 0 1rem;
  }
}
.section .section__nav li.is-active a {
  color: #000;
  background-color: rgba(255, 255, 255, 0.9);
}
.section .section__nav li:hover a {
  box-shadow: 0 -5rem 5rem inset rgba(125, 125, 125, 0.75);
}
.section .section__nav a {
  display: inline-block;
  text-decoration: none;
  padding: 2.9rem 1rem 2.8rem;
  border: 0.1rem solid rgba(255, 255, 255, 0.3);
  font-weight: 700;
  width: 100%;
  text-align: center;
  transition: box-shadow 0.4s;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1023px) {
  .section .section__nav a {
    padding: 2rem 1rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__nav a {
    padding: 1.5rem 0.75rem;
  }
}
.section .footer__bar a {
  line-height: 1.44;
  text-decoration: none;
}
.section .footer__bar a:hover {
  text-decoration: underline;
}
.section .section__aside {
  padding-top: 5.1rem;
  max-width: 34.16%;
  flex: 0 0 34.16%;
  text-align: right;
  font-size: 1.6rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 1199px) {
  .section .section__aside {
    flex: 0 0 40%;
    max-width: 40%;
  }
}
@media (max-width: 1023px) {
  .section .section__aside {
    flex: 0 0 35%;
    max-width: 35%;
    padding-top: 4.2rem;
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__aside {
    flex-direction: row;
    padding: 2rem 0;
    flex: 0 0 100%;
    max-width: 100%;
    font-size: 1.4rem;
  }
}
.section .section__aside h3 {
  display: inline-block;
  margin: 0 1.9rem 0 0;
}
@media (max-width: 1023px) {
  .section .section__aside h3 {
    display: block;
    margin: 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__aside h3 {
    margin-right: 2.5rem;
  }
}
@media (max-width: 575px) {
  .section .section__aside h3 {
    margin-right: 1.5rem;
  }
}
.section .section__aside a {
  line-height: 1.44;
  text-decoration: none;
}
.section .section__aside a:hover {
  text-decoration: underline;
}

/* section centered */
.section--centered {
  padding: 0 0 9.9rem;
  position: relative;
  z-index: 4;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section--centered {
    padding-bottom: 4rem;
  }
}
.section--centered .section__nav {
  margin: 0 auto;
}
@media (max-width: 1023px) {
  .section--centered .section__nav {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
.section--centered .section__nav ul {
  margin: 0 -1.1rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section--centered .section__nav ul {
    margin: 0 -1rem;
  }
}
@media (max-width: 575px) {
  .section--centered .section__nav ul {
    margin: 0 0 -1rem;
  }
}
.section--centered .section__nav li {
  padding: 0 1.1rem;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section--centered .section__nav li {
    padding: 0 1rem;
  }
}
@media (max-width: 575px) {
  .section--centered .section__nav li {
    padding: 0 0 1rem;
  }
}

/* ------------------------------------------------------------ *\
	Select
\* ------------------------------------------------------------ */
.select select::-ms-expand {
  display: none;
}

/* ------------------------------------------------------------ *\
  Shell
\* ------------------------------------------------------------ */
.shell {
  max-width: calc( 120rem + 1.5rem * 2 );
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: auto;
  width: 100%;
}

/* Shell Fluid */
.shell--fluid {
  max-width: none;
}

/* ------------------------------------------------------------ *\
	Video
\* ------------------------------------------------------------ */
.video {
  padding-bottom: 33.3%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 4;
}
@media (max-width: 1023px) {
  .video {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .video {
    padding-bottom: 50%;
  }
}
@media (max-width: 575px) {
  .video {
    padding-bottom: 60%;
  }
}
@media (max-width: 374px) {
  .video {
    padding-bottom: 70%;
  }
}
.video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.video.samsung video {
  object-fit: fill;
  object-position: top center;
}
.video figure {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -2.1rem;
  width: 10rem;
  height: 10rem;
  line-height: 10rem;
  border-radius: 50%;
  text-align: center;
  background-color: #282828;
  transition: opacity 0.4s;
}
@media (max-width: 1023px) {
  .video figure {
    width: 7.5rem;
    height: 7.5rem;
  }
}
.video figure.is-active {
  opacity: 0;
  pointer-events: none;
}
.video figure svg {
  display: inline-block;
  vertical-align: middle;
  fill: #fff;
  margin: -0.3rem -0.6rem 0 0;
}
@media (max-width: 1023px) {
  .video figure svg {
    margin: -2.5rem -0.7rem 0 0;
  }
}
.video .video__title {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  margin-top: 6rem;
  padding-left: 1rem;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.6em;
}
@media (max-width: 1023px) {
  .video .video__title {
    margin-top: 4rem;
    letter-spacing: 0.3em;
  }
}
.video .video__title.is-active {
  top: 0;
  transform: none;
  margin: 0;
  padding: 1rem 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  pointer-events: none;
}

.video--small {
  padding-bottom: 47.5%;
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .video--small {
    padding-bottom: 60%;
  }
}
@media (max-width: 374px) {
  .video--small {
    padding-bottom: 70%;
  }
}
.video--small figure {
  margin-top: -0.8rem;
}
.video--small .video__title {
  position: absolute;
  top: auto;
  transform: none;
  bottom: 0;
  width: auto;
  letter-spacing: 0;
  margin-top: 0;
  text-transform: capitalize;
  left: 0;
  color: #fff;
  background-color: #000;
  padding: 2.1rem 3.7rem 2.1rem 3rem;
}
.video--small .video__title.is-active {
  bottom: auto;
  width: 100%;
}

/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */
.wrapper {
  position: relative;
  min-height: 100vh;
  background-image: url("../images/temp/noise-3.png");
  background-color: #FFF7EF;
  background-repeat: repeat;
  background-position: 0 0;
  overflow: hidden;
}
.wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-image: url("../images/temp/Noise2.png");
  pointer-events: none;
}

/* ------------------------------------------------------------ *\
	Overwrites
\* ------------------------------------------------------------ */