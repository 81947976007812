/* ------------------------------------------------------------ *\
	Nav Trigger
\* ------------------------------------------------------------ */

.nav-trigger {
	display: inline-block;
	width: 5rem; 
	height: 5rem;
	background-color: $c-black;
	position: relative;

	span {
		display: inline-block;
		height: 0.2rem; 
		width: 2.4rem;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: $c-white;
		transition: opacity $t-duration;
	}

	&:before,
	&:after {
		content: "";
		position: relative;
		height: 0.2rem; 
		width: 2.4rem;
		position: absolute;
		left: 50%;
		background-color: $c-white; 
		transform: translateX(-50%);
		transition: transform $t-duration;
	}

	&:before {
		top: 50%;
		transform: translate(-50%, calc(-50% - 0.5rem));
	}

	&:after {
		bottom: 50%; 
		transform: translate(-50%, calc(50% + 0.5rem));
	}

	&.is-active span {
		opacity: 0;
	}

	&.is-active:before {
		transform: translate(-50%, -50%) rotate(45deg);

	}

	&.is-active:after {
		transform: translate(-50%, 50%) rotate(-45deg);
	}
}